export enum IconName {
  afterDeath = 'afterDeath',
  coverage = 'coverage',
  education = 'education',
  educationCoverageCalculator = 'educationCoverageCalculator',
  extremeSports = 'extremeSports',
  debtsSavings = 'debtsSavings',
  alcohol = 'alcohol',
  car = 'car',
  confirmation = 'confirmation',
  checklist = 'checklist',
  death = 'death',
  drugs = 'drugs',
  edit = 'edit',
  family = 'family',
  familyIncome = 'familyIncome',
  future = 'future',
  health = 'health',
  hospital = 'hospital',
  marijuana = 'marijuana',
  mib = 'mib',
  payment = 'payment',
  personalInformation = 'personalInformation',
  police = 'police',
  plane = 'plane',
  preference = 'preference',
  smoking = 'smoking',
  summary = 'summary',
  weight = 'weight',
  work = 'work',
  wait = 'wait',
  poorHealth = 'poorHealth',
  goodHealth = 'goodHealth',
  greatHealth = 'greatHealth',
  excellentHealth = 'excellentHealth',
  poorFinancialHealth = 'poorFinancialHealth',
  averageFinancialHealth = 'averageFinancialHealth',
  goodFinancialHealth = 'goodFinancialHealth',
  excellentFinancialHealth = 'excellentFinancialHealth',
  error404 = 'error404',
  calculatorCoverage = 'calculatorCoverage',
  confirmationOutlined = 'confirmationOutlined',
  contact = 'contact',
  fileDownload = 'fileDownload',
  loading = 'loading',
  loadingCalculator = 'loadingCalculator',
  watch = 'watch',
  advisor = 'advisor',
  briefcase = 'briefcase',
  bullet = 'bullet',
  bulletCheckmark = 'bulletCheckmark',
  bulletEx = 'bulletEx',
  check = 'check',
  checkmark = 'checkmark',
  circledApplication = 'circledApplication',
  circledSignature = 'circledSignature',
  close = 'close',
  copy = 'copy',
  day = 'day',
  file = 'file',
  handshake = 'handshake',
  mail = 'mail',
  morning = 'morning',
  night = 'night',
  phone = 'phone',
  unfortunately = 'unfortunately',
  update = 'update',
  close2 = 'close2',
  success = 'success',
  successOutlined = 'successOutlined',
  warning = 'warning',
  warningOutlined = 'warningOutlined',
  error = 'error',
  errorOutlined = 'errorOutlined',
  information = 'information',
  informationOutlined = 'informationOutlined',
  triangle = 'triangle',
  dragHandle = 'dragHandle',
  folder = 'folder',
  fileMenu = 'fileMenu',
  list = 'list',
  question = 'question',
  threeDots = 'threeDots',
  search = 'search',
  producerCog = 'producerCog',
  producerUmbrella = 'producerUmbrella',
  producerWrench = 'producerWrench',
  producerCogAndSearch = 'producerCogAndSearch',
  leftArrow = 'leftArrow',
  backArrow = 'backArrow',
  github = 'github',
  group = 'group',
}
